<script setup lang="ts">
withDefaults(defineProps<{ size?: string }>(), {
	size: "150px"
});

const prepareImgUrl = useImage();
const imageBase = `url(${prepareImgUrl(`/nuxt-img/wheel-of-fortune/base.png`, {
	format: "avif",
	width: 480,
	height: 480,
	loading: "lazy"
})})`;
</script>

<template>
	<div class="wheel-of-fortune-animated">
		<NuxtImg
			class="arrow"
			src="/nuxt-img/wheel-of-fortune/arrow-light.png"
			format="avif"
			alt="arrow"
			width="115"
			height="269"
			preload
		/>
		<div class="sectors">
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/sectors.png"
				format="avif"
				alt="wheel"
				width="432"
				height="431"
				preload
			/>
		</div>
		<div class="button">
			<NuxtImg
				src="/nuxt-img/wheel-of-fortune/button.png"
				format="avif"
				alt="button"
				width="136"
				height="136"
				preload
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wheel-of-fortune-animated {
	position: relative;
	z-index: 1;
	width: v-bind(size);
	height: v-bind(size);
	background: v-bind(imageBase) no-repeat center center / contain;
	img {
		max-width: 100%;
		height: auto;
	}
	> * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.arrow {
		z-index: 3;
		width: 24%;
		top: 25%;
		left: 49.7%;
	}
	.sectors {
		z-index: 2;
		width: 90%;
		display: flex;
		animation: animation-drum 10s ease-in-out infinite;
	}
	.button {
		z-index: 4;
		width: 28%;
		display: flex;
		scale: 1;
	}
}

@keyframes animation-drum {
	0%,
	100% {
		transform: translate(-50%, -50%) rotate(0);
	}
	37% {
		transform: translate(-50%, -50%) rotate(730deg);
	}
	43.3939%,
	99.9999% {
		transform: translate(-50%, -50%) rotate(720deg);
	}
}
</style>
